@import 'declarations';
@import '../../../node_modules/bootstrap/scss/reboot';

ol,
ul,
dl,
blockquote,
pre,
figure,
legend {
  margin: 0;
}

button {
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
  color: inherit;

  &:focus {
    outline: none;
  }
}

input {
  outline: none;
  border: none;
  padding: 0;

  // Hide auto fill button on Safari
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
  }
}

ul,
ol {
  list-style: none;
  padding: 0;
}

body {
  font-family: var(--py-font-primary);
}

a,
button,
label,
input,
select {
  font-family: var(--py-font-secondary);
}
